//useAudioRecorder.js
import { useCallback, useState } from "react";

export const useAudioRecorder = ({
    onData = () => { }
}) => {

    const [stream, setStream] = useState(null);
    const [input, setInput] = useState(null);
    const [node, setNode] = useState(null);
    const [encoder, setEncoder] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [isRecording, setIsRecording] = useState(false);

    const gotUserMedia = (_encoder) => (localMediaStream) => {
        if (_encoder) {
            console.log('success grabbing microphone');
            const _stream = localMediaStream;

            let audio_context;
            if (typeof AudioContext !== 'undefined') {
                audio_context = new AudioContext();
            } else {
                console.error('JavaScript execution environment (Browser) does not support AudioContext interface.');
                alert('Could not start recording audio:\n Web Audio is not supported by your browser!');
                return;
            }
            let _input = audio_context.createMediaStreamSource(_stream);
            let _node;

            if (_input.context.createJavaScriptNode)
                _node = _input.context.createJavaScriptNode(4096, 1, 1);
            else if (_input.context.createScriptProcessor)
                _node = _input.context.createScriptProcessor(4096, 1, 1);
            else
                console.error('Could not create audio node for JavaScript-based Audio Processing.');

            _encoder.postMessage({
                cmd: 'init',
                config: {
                    samplerate: 44100,
                    channels: 1,
                    bps: 16,
                    compression: 5
                }
            });

            _node.onaudioprocess = function (e) {
                // see also: http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
                let channelLeft = e.inputBuffer.getChannelData(0);
                _encoder.postMessage({ cmd: 'encode', buf: channelLeft });
            };

            _input.connect(_node);
            _node.connect(audio_context.destination);

            setInput(_input);
            setNode(_node);
            setStream(_stream);
            setAudioContext(audio_context);
        }
    }

    const startRecording = () => {
        setIsRecording(true);
        console.log('start recording');
        const _encoder = new Worker('encoder.js');
        _encoder.onmessage = function (e) {
            if (e.data.cmd === 'end') {
                console.log('e.data', e.data)
                onData(e.data.buf);
            }
        };
        setEncoder(_encoder);

        if (navigator.webkitGetUserMedia)
            navigator.webkitGetUserMedia({ video: false, audio: true }, gotUserMedia(_encoder), userMediaFailed);
        else if (navigator.mozGetUserMedia)
            navigator.mozGetUserMedia({ video: false, audio: true }, gotUserMedia(_encoder), userMediaFailed);
        else
            navigator.getUserMedia({ video: false, audio: true }, gotUserMedia(_encoder), userMediaFailed);
    }

    const stopRecording = useCallback(() => {
        console.log('invoked stopRecording')
        console.log('encoder', encoder);
        console.log('stream', stream);
        console.log('input', input);
        console.log('node', node);
        setIsRecording(false);
        if (encoder && stream && input && node) {
            console.log('stop recording');
            const tracks = stream.getAudioTracks()
            for (let i = tracks.length - 1; i >= 0; --i) {
                tracks[i].stop();
            }
            encoder.postMessage({ cmd: 'finish' });

            input.disconnect();
            node.disconnect();
            setInput(null);
            setNode(null);
            setStream(null);
            setAudioContext(null);
        }
    }, [input, node, encoder, stream])

    const destroy = useCallback(() => {
        if (encoder) {
            encoder.terminate();
            setEncoder(null);
        }
    }, [encoder])

    return {
        start: startRecording,
        stop: stopRecording,
        destroy,
        isRecording,
        stream,
        audioContext,
    };
}

function userMediaFailed(code) {
    console.log('grabbing microphone failed: ' + code);
}
