import { InterviewAI } from "./components/InterviewAI";
import { LiveModel } from "./components/LiveModel";

function App() {

  return (
    <div className='flex flex-col md:flex-row p-1 debug'>
      <LiveModel />
      <div className='flex-1'>
        <InterviewAI />
      </div>
    </div>
  )
}

export default App;


