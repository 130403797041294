import { getTextToSpeech } from "../api";

export const TextToSpeech = {
    speak
}

const VOICES = {
    'en-US': 'JennyNeural',
    'ja-JP': 'NanamiNeural',
}

/* 
ja-JP-NanamiNeural
ja-JP-AoiNeural
ja-JP-MayuNeural
ja-JP-ShioriNeural
*/

async function speak({ text, language }, setLoading = () => { }) {
    const voiceName = VOICES[language];
    console.log('language',language)
    const gender = 'Female';
    try {
        const audioBlob = await getTextToSpeech({
            text,
            language,
            gender,
            voiceName
        }, setLoading);

        if (audioBlob) {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.play();
        }
    } catch (error) {
        console.error(`Can't speak`, error);
    }

}