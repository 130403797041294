const JSON_HEADER = {
    "Content-Type": "application/json",
}

const API_URL = process.env.REACT_APP_API_URL;

export const getFirstQuestion = ({ language }, setLoading = () => { }) => {

    return _fetch(`${API_URL}/first-question?language=${language}`, {
        method: 'GET',
        setLoading
    });
}

export const getNextQuestion = ({ question, language }, setLoading = () => { }) => {

    return _fetch(`${API_URL}/next-question?language=${language}`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify({
            question
        }),
        setLoading,
    });
}

export const sendAudioToAPI = ({ language, audioBlob }, setLoading = () => { }) => {
    let data = new FormData();
    data.append('channel_count', 1);
    data.append('sample_rate_hertz', 44100);
    data.append('audio', audioBlob, 'audio.flac');

    return _fetch(`${API_URL}/speech-to-text?language=${language}`, {
        method: 'POST',
        body: data,
        setLoading,
    });
};

export const getTextToSpeech = ({
    text,
    language,
    gender,
    voiceName,
}, setLoading) => {

    return _fetch(`${API_URL}/text-to-speech?language=${language}&gender=${gender}&voiceName=${voiceName}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text
        }),
        setLoading,
        blobResponse: true,
    })
}

function _fetch(url, {
    setLoading = () => { },
    blobResponse,
    ...rest
}) {
    setLoading(true);
    return fetch(url,
        rest).then(it => {
            setLoading(false);
            return blobResponse ? it.blob() : it.json();
        }).catch(error => {
            setLoading(false);
            console.error(error);
            return { error };
        });
}