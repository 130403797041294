import { createContext } from "react";
import { LANGUAGES } from "../constants";
import { useLocalStorage } from "@uidotdev/usehooks";

export const LanguageContext = createContext();

export const LanguageContextProvider = ({
    children
}) => {
    const [language, setLanguage] = useLocalStorage('shop-ai-language', LANGUAGES.jp.code);

    return (
        <LanguageContext.Provider value={{
            language,
            setLanguage
        }}>
            {children}
        </LanguageContext.Provider>
    )
}